import { useEffect, useRef, useDebugValue } from 'react';

export class CancelledError extends Error {
  constructor(message) {
    super(message);
    this.name = "CancelledError";
    this.isCancelled = true;
  }
}

/**
 * Devuelve una funcion que puede usarse para envolver promesas, las promesas envueltas seran automaticamente canceladas
 * al desmontar el componente.
 *
 * La promesas canceladas automaticamente reciben un CancelledError como argumento, que permite identificar que se rechazo
 * por cancelacion, para evitar hacer uso de setState.
 *
 * Ejemplo:
 *
 * const asyncWrapper = useAsyncWrap();
 *
 * asyncWrapper(fetch('/test'))
 *  .then(res => {console.log(res)})
 *  .catch(err => {
 *    if(!err.isCancelled){
 *      setStateFunc('Ocurrio un error');
 *    }
 *  })
 *
 *  o tambien (err instanceof CancelledError)
 *
 *  Nota: Este hook rechaza las promesas, pero no cancela las requests, en algunos casos tiene sentido cancelar
 *  las request EJ: Un fileupload en progreso, etc. Esos requieren usar en cancel token o cancel function de axios (O equivalente)
 *  ademas de este hook
 *
 * @returns {function(*): Promise<unknown>}
 */
const useAsyncWrap = () => {
  const rejectFunctions = useRef([]);

  useDebugValue(rejectFunctions, rejectFunctions => rejectFunctions.current.length);

  useEffect(() => {
    return () => {
      rejectFunctions.current.forEach((rejectFunct) => {
        rejectFunct(new CancelledError('Cancelled promise'));
      });

      rejectFunctions.current = [];
    }
  }, []);

  return (promise) => {
    return new Promise((resolve, reject) => {
      rejectFunctions.current.push(reject);

      promise.then(resolve)
             .catch(reject)
             .then(() => {
                const index = rejectFunctions.current.indexOf(reject);

                if(index >= 0){
                  rejectFunctions.current.splice(index, 1);
                }
              })
    });
  }
}

export default useAsyncWrap;
