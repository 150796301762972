import React, {useState, useEffect, useRef, useCallback} from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './NotificationsPage.scss';
import {Button, Container} from "react-bootstrap";
import {connect} from "react-redux";
import {loadMoreNotifications, registerNotificationRead} from "../../store/actions/notifications";
import Spinner from "../../components/Spinner/Spinner";
import {useScrollYPosition} from 'react-use-scroll-position';
import Notification from "../../components/Notification/Notification";

const NotificationsPage = ({
                             loadMoreNotifications,
                             registerNotificationRead,
                             notifications,
                             oldestNotificationReached,
}) => {
  const scrollEndElement = useRef();
  const scrollY = useScrollYPosition();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingError, setLoadingError] = useState(null);

  const loadMoreHandler = useCallback(async () => {
    setLoadingMore(true);

    try{
      await loadMoreNotifications();
      setLoadingMore(false);
      setLoadingError(null);
    }catch(e){
      setLoadingError('Error al traer mas notificaciones');
    }

    setLoadingMore(false);
  }, [loadMoreNotifications]);

  useEffect(() => {
    if(notifications === null){
      setLoadingError('Error al cargar las notificaciones');
    }
  }, [notifications]);

  useEffect(() => {
    if (!loadingError && scrollEndElement.current && (scrollY + window.innerHeight > scrollEndElement.current.offsetTop) && !loadingMore && !oldestNotificationReached) {
      loadMoreHandler();
    }
  }, [scrollY, scrollEndElement, loadingMore, oldestNotificationReached, notifications, loadMoreHandler, loadingError])

  return (
    <DefaultLayout title="Notificaciones">
      <div className="NotificationsPage">
        <Container fluid="md" className="container--xs-full main-container">
          {notifications && notifications.length
            ? (
              <div className="page-listing page-listing--unique">
                {notifications.map(notification => (
                  <Notification
                    key={notification.id}
                    onRead={registerNotificationRead}
                    className={"page-listing__item"}
                    notification={notification}
                  />
                ))}
                <div ref={scrollEndElement} className="scroll-end"/>
              </div>
            ) : (
              oldestNotificationReached && (
                <p>No hay notificaciones</p>
              )
            )
          }
          <div className="loading-status">
            {loadingMore && (<Spinner/>)}
            {loadingError && (
              <div className="loading-status__error">
                <p>Ocurrio un error al cargar</p>
                <Button
                  variant="primary"
                  disabled={loadingMore}
                  onClick={loadMoreHandler}
                >
                  Reintentar
                </Button>
              </div>
            )}
          </div>
        </Container>
      </div>
    </DefaultLayout>
  );
}

NotificationsPage.propTypes = {

}

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
  oldestNotificationReached: state.notifications.oldestNotificationReached,
})

const mapDispatchToProps = {
  loadMoreNotifications,
  registerNotificationRead,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);