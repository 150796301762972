import React, {useEffect, useState} from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './SimulatorPage.scss';
import {Container, Row, Col} from "react-bootstrap";
import SimulatorHeaderImage from '../../assets/images/simulator_header.png';
import Simulator from "../../components/Simulator/Simulator";
import AsyncContent from "../../components/AsyncContent/AsyncContent";
import campaignsAPI from "../../api/campaignsAPI";
import useAsyncWrap from "../../hooks/useAsyncWrap";

const SimulatorPage = (props) => {
  const asyncWrapper = useAsyncWrap();

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [currentCampaign, setCurrentCampaign] = useState(null);

  //TODO: Load campaign in redux.

  const loadCurrentCampaign = async() => {
    setLoading(true);
    setLoadingError(null)
    setCurrentCampaign(null);

    try{
      const response = await asyncWrapper(campaignsAPI.showCurrent());
      setCurrentCampaign(response.data);
      setLoading(false);
    }catch(e){
      if(!e.isCancelled){
        setLoadingError('Error al cargar informacion del simulador');
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    loadCurrentCampaign();
  }, []);

  return (
    <DefaultLayout title="Simulador">
      <div className="SimulatorPage">
        <Container fluid="md" className="container--xs-full">
          <Row className="no-gutters">
            <Col className="spacing" xs={12} md={1} lg={3}/> {/* offset en bs-react? */}
            <Col className="" md={10} xs={12} lg={6}>
              <div className="simulator">
                <img
                  className="simulator__header"
                  src={SimulatorHeaderImage}
                  alt="Header simulador"
                />
                <div className="simulator__body">
                  <AsyncContent
                    hasError={!!loadingError}
                    errorMessage={loadingError}
                    isLoading={loading}
                    onRetryHandler={loadCurrentCampaign}
                  >
                    {currentCampaign && (<Simulator product={currentCampaign.product}/>)}
                    {!loading && !loadingError && !currentCampaign && (<p>No hay productos disponibles</p>)}
                  </AsyncContent>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </DefaultLayout>
  );
}

SimulatorPage.propTypes = {

};

export default SimulatorPage;