import {CLOSE_SIDE_MENU, OPEN_SIDE_MENU, SET_AVATAR_MODAL_IS_OPEN, TOGGLE_SIDE_MENU} from "../types/menu";

export const toggleSideMenu = () => dispatch => {
  dispatch({ type: TOGGLE_SIDE_MENU });
}

export const openSideMenu = () => dispatch => {
  dispatch({ type: OPEN_SIDE_MENU });
}

export const closeSideMenu = () => dispatch => {
  dispatch({ type: CLOSE_SIDE_MENU });
}

export const setAvatarModalIsOpen = (open) => dispatch => {
  dispatch({type: SET_AVATAR_MODAL_IS_OPEN, payload: open})
};
