import React, {useEffect, useState} from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import {Col, Row, Container, FormGroup, Button, Form} from "react-bootstrap";
import {AiOutlineFilter} from "react-icons/ai";
import route from "../../helpers/route";
import {useFormik} from "formik";
import operationsAPI from "../../api/operationsAPI";
import AsyncContent from "../../components/AsyncContent/AsyncContent";
import Paginator from "../../components/Paginator/Paginator";
import Operation from "../../components/Operation/Operation";
import './OperationsPage.scss';
import useAsyncWrap from "../../hooks/useAsyncWrap";

let submitTimeout;

const OperationsPage = (props) => {
  const asyncWrapper = useAsyncWrap();

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [operationsPagination, setOperationsPagination] = useState(null);

  const loadOperations = async(filters) => {
    setLoading(true);
    setOperationsPagination(null);
    setLoadingError(null);

    try{
      const response = await operationsAPI.index(filters);
      setOperationsPagination(response.data);
      setLoading(false);
    }catch(e){
      if(!e.isCancelled){
        setLoadingError(true);
        setLoading(false);
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      search: '',
      page: 1,
      per_page: 24,
    },
    onSubmit(filters){
      clearTimeout(submitTimeout);
      submitTimeout = null;

      loadOperations(filters);
    }
  });

  useEffect(() => {
    formik.submitForm();
  },[formik.values.page, formik.values.per_page]);

  useEffect(() => {
    clearTimeout(submitTimeout);
    submitTimeout = setTimeout(formik.submitForm, 500);

    return () => {
      clearTimeout(submitTimeout);
    }
  },[formik.values.search, formik.submitForm]);

  return (
    <DefaultLayout title="Operaciones">
      <div className="OperationsPage">
        <div className="main-container">
          <Container fluid="xl" className="container--xs-full">
            <div className="page-card">
              {/*<h2 className="page-card__title">
                Operaciones
              </h2>*/}
              <div className="page-card__content">
                <Form onSubmit={formik.handleSubmit} className="filter-form">
                  <Row className="no-gutters">
                    <Col xs={10} sm={11} md={4}>
                      <FormGroup>
                        <input
                          type="text"
                          id="search"
                          name="search"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          value={formik.values.search}
                          placeholder="Ingresá Nombre, Apellido o aut"
                          className="filter-form__input form-control"
                          disabled={0 && loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={2} sm={1} md={2}>
                      <Button type="submit" variant="default" disabled={loading}>
                        <AiOutlineFilter/>
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <div className="">
                  <AsyncContent
                    hasError={!!loadingError}
                    isLoading={loading}
                    onRetryHandler={() => loadOperations(formik.values)}
                  >
                    <Row>
                      {operationsPagination && operationsPagination.data.map(operation => (
                        <Col key={operation.id} xs={12} sm={6} lg={4}>
                          <Operation
                            operation={operation}
                            to={route({name: 'operation_show', params: {id: operation.id}})}
                          />
                        </Col>
                      ))}

                      {operationsPagination && operationsPagination.data.length === 0 && (
                        <Col xs={12}><h5 className="no-records">No hay operaciones</h5></Col>
                      )}
                    </Row>
                  </AsyncContent>
                </div>
                <div className="paginator-container">
                  {operationsPagination && <Paginator
                    isLoading={loading}
                    onPageChangeHandler={page => formik.values.page = page}
                    meta={operationsPagination.meta}
                  />}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </DefaultLayout>
  );
}

OperationsPage.propTypes = {

};

export default OperationsPage;