import React from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import {connect} from "react-redux";
import './QrCodePage.scss';
import {Button, Container, Image} from "react-bootstrap";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";

const QrCodePage = ({user}) => {
  return (
    <DefaultLayout title="Código QR">
      <div className="QrCodePage">
        <Container fluid>
          <div className="qr-block">
            <Image
              src={user.business.qr_code_image}
              className="qr-block__code"
            />
            <div className="qr-block__buttons">
              <Button
                className="semi-block"
                variant={"primary"}
                href={user.business.qr_code_image_download}
                download
              >
                Descargar
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </DefaultLayout>
  )
}

QrCodePage.propTypes = {

}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(QrCodePage);