import { applyMiddleware, createStore } from "redux";
import reduxthunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import RootReducer from "./reducers";

/* Solo persistimos los tokens y si esta logueado, el perfil lo traemos cuando inicia la app */
const authTransform = createTransform(
  (inboundState, key) => ({
      tokens: inboundState.tokens,
      isAuth: inboundState.isAuth,
  }),
  (outboundState, key) => ({
    ...outboundState,
    tokens: outboundState.tokens,
    user: null,
    isAuth: outboundState.isAuth,
    isUserLoading: outboundState.isAuth,
  }),
  {whitelist: ['auth']}
);
/* Solo persistimos los tokens y si esta logueado, el perfil lo traemos cuando inicia la app */

const persistConfig = {
  key: 'decreditos-comercio.auth',
  storage,
  version: 2,
  whitelist: ['auth'],
  transforms: [
    authTransform,
  ]
}

const persistedReducer = persistReducer(persistConfig, RootReducer);
const middlewares = [reduxthunk];

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares));

export const store = createStore(persistedReducer, devTools);
export const persistor = persistStore(store);
