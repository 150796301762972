import React, {useEffect, useMemo, useRef, useState, useCallback} from 'react';
import operationDocumentsAPI from "../../api/operationDocumentsAPI";
import {Button, Col, Row} from "react-bootstrap";
import MultiFileUploader from "../MultiFileUploader/MultiFileUploader";
import {AiOutlineEye, AiOutlineStop, AiOutlineReload, AiOutlineFileAdd, AiOutlineDelete} from 'react-icons/ai';
import PropTypes from "prop-types";
import Spinner from "../Spinner/Spinner";
import './OperationFormDocumentation.scss';
import HttpService from "../../api/HttpService";

const OperationFormDocumentation = ({operation, locked = false, visible = true, onRequestClearance}) => {

  const [localDocuments, setLocalDocuments] = useState(operation.documents.map(document => ({
    key: document.id,
    document,
    localFile: null,
    loading: null,
    error: null,
    cancel: null,
    previewUrl: document.file_url,
  })));

  const storedDocumentsCount = useMemo(() => localDocuments.filter(doc => doc.document).length, [localDocuments])

  /* Al morir el componente cancelamos los uploads en curso */
  const localDocumentsRef = useRef(localDocuments);

  useEffect(() => {
    localDocumentsRef.current = localDocuments;
  }, [localDocuments])

  useEffect(() => {
    return () => localDocumentsRef.current.forEach(localDocument => {
      localDocument.cancel && localDocument.cancel();
    });
  }, [])
  /* Al morir el componente cancelamos los uploads en curso */

  /**
   * Helper para actualizar el estado de un item en el array de documentos locales
   *
   * @param document
   * @param changes
   */
  const updateLocalDocument = useCallback((document, changes) => {
    setLocalDocuments(oldDocuments => {
      const index = oldDocuments.findIndex(doc => doc.key === document.key);
      const newDocument = {...oldDocuments[index], ...changes};
      const newDocuments = oldDocuments.slice()
      newDocuments.splice(index, 1, newDocument);
      return newDocuments;
    });
  }, []);

  /**
   *
   * @param localDocument
   * @returns {Promise<void>}
   */
  const storeDocument = useCallback(async localDocument => {
    try{
      const formData = new FormData();
      formData.append('file', localDocument.localFile);
      formData.append('operation_id', operation.id);

      const response = await operationDocumentsAPI.store(formData, {
        onUploadProgress: progressEvent => updateLocalDocument(localDocument, {loading: Math.round((progressEvent.loaded / progressEvent.total) * 100)}),
        cancelToken: new HttpService.CancelToken(cancel => updateLocalDocument(localDocument, {cancel})),
      });

      updateLocalDocument(localDocument, {loading: null, error: null, document: response.data, cancel: null});
    }catch(e){
      let errorMessage = 'Error al subir imagen';

      if(HttpService.errorIsCancel(e)){
        errorMessage = 'Cancelado';
      }else if(e?.response?.status === 422){
        errorMessage = 'La imagen es invalida';
      }

      updateLocalDocument(localDocument, {loading: null, error: errorMessage, document: null, cancel: null});
    }
  }, [updateLocalDocument, operation.id]);

  /**
   * Devuelve la estructura de un localDocument a partir de un File de html (fileInput.files[0])
   *
   * @param file
   * @returns Object
   */
  const createLocalDocumentFromLocalFile = file => {
    const key = + new Date(); //TODO: Usar algo decente
    const previewUrl = URL.createObjectURL(file); //TODO: Ver compatibilidad

    return {
      key,
      localFile: file,
      document: null,
      previewUrl,
      loading: 0,
      error: null,
      cancel: null,
    }
  }

  /**
   * Handler para cuando se agrega un archivo
   *
   * @param file
   * @returns {Promise<void>}
   */
  const onFileAdded = file => {
    const newDocument = createLocalDocumentFromLocalFile(file);
    setLocalDocuments([...localDocuments, newDocument]);
    return storeDocument(newDocument);
  }

  /**
   * Handler para el boton de eliminar documento
   *
   * @param localDocument
   * @returns {Promise<void>}
   */
  const onLocalDocumentDeleted = useCallback(async localDocument => {
    updateLocalDocument(localDocument, {loading: -1});

    if(localDocument.document){
      try{
        await operationDocumentsAPI.destroy(localDocument.document.id);
      }catch(e){
        updateLocalDocument(localDocument, {loading: null});
        //TODO: Mostrar mensaje de que no se pudo eliminar en algun lado
        return;
      }
    }

    setLocalDocuments(oldLocalDocuments => oldLocalDocuments.filter(doc => doc.key !== localDocument.key));
  }, [updateLocalDocument]);

  /**
   * Handler para el boton de reintentar el upload de un documento que fallo al subirse
   *
   * @param localDocument
   * @returns {Promise<void>}
   */
  const onLocalDocumentRetry = localDocument => {
    if(localDocument.error){
      const newDocument = createLocalDocumentFromLocalFile(localDocument.localFile);
      updateLocalDocument(localDocument, newDocument);

      return storeDocument(newDocument);
    }
  }

  if(!visible){
    return null;
  }

  return (
    <div className="OperationFormDocumentation page-card">
      {!locked ? (
        <>
          <h2 className="page-card__title">
            Formularios
          </h2>
          <div className="page-card__content">
            <Row className="mb-4">
              <Col xs={12} className="mb-2">
                <Button
                  target="_blank"
                  href={operation.documentation_download_url}
                  className="form-button semi-block"
                  variant="primary"
                >
                  Descargar formularios
                </Button>
              </Col>
              {operation.waiting_for_documentation && (
                <Col xs={12}>
                  <Button
                    className="form-button semi-block"
                    variant="outline-primary"
                    disabled={storedDocumentsCount < 2}
                    title={storedDocumentsCount < 2 ? 'Debe cargar archivos para poder solicitarlo' : ''}
                    onClick={onRequestClearance}
                  >
                    Solicitar liquidacion
                  </Button>
                </Col>
              )}
            </Row>
          </div>
          <h2 className="page-card__title">
            Subir formularios
          </h2>
          <div className="page-card__content">
            <Row className="mb-4">
              <Col xs={12}>
                {operation.waiting_for_review && (
                  <p className="text-muted">
                    La documentacion esta esperando revision por parte de DeCreditos, te notificaremos luego de revisarla.
                    Si falta documentacion podes adjuntarla.
                  </p>
                )}

                {operation.waiting_for_documentation && localDocuments.length >= 2 && (
                  <p className="text-muted">
                    Una vez que subas toda la documentacion, podes solicitar la liquidacion del prestamo.
                  </p>
                )}
              </Col>
              <Col xs={12}>
                <MultiFileUploader
                  accept="image/*;capture=camera"
                  onFileAdded={onFileAdded}
                >
                  {({addImage}) => (
                    <div className="scroller">
                      <div className="documents">
                        <div className="documents__img-container">
                          <div className="documents__img documents__img--blank">
                            <Button
                              disabled={localDocuments.length >= 6}
                              onClick={addImage}
                              className="documents__img-button documents__img-button--add"
                            >
                              <AiOutlineFileAdd/>
                            </Button>
                          </div>
                        </div>

                        {localDocuments.slice().reverse().map((localDocument) => (
                          <div className="documents__img-container" key={localDocument.key}>
                            <div
                              className={`documents__img ${localDocument.loading ? 'documents__img--loading' : ''} ${localDocument.error ? 'documents__img--error' : ''}`}
                              style={{backgroundImage: `url('${localDocument.previewUrl}')`}}
                            >
                              <div className="documents__img-spinner-container">
                                <Spinner size={15} zIndex={10}/>
                              </div>
                              {localDocument.loading !== null ? (
                                <>
                                  <div className="documents__img-loading-bar" style={{height: `${localDocument.loading}%`}}/>
                                  {localDocument.cancel && (
                                    <Button
                                      onClick={() => localDocument.cancel()}
                                      className="documents__img-button documents__img-button--cancel"
                                      title="Cancelar"
                                    >
                                      <AiOutlineStop/>
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Button
                                    onClick={() => onLocalDocumentDeleted(localDocument)}
                                    className="documents__img-button documents__img-button--remove"
                                    title="Eliminar"
                                  >
                                    <AiOutlineDelete/>
                                  </Button>
                                  {localDocument.error === null ? (
                                    <Button
                                      target="_blank"
                                      href={localDocument.previewUrl}
                                      className="documents__img-button documents__img-button--download"
                                      title="Ver"
                                    >
                                      <AiOutlineEye/>
                                    </Button>
                                  ) : (
                                    <>
                                      <Button
                                        onClick={() => onLocalDocumentRetry(localDocument)}
                                        className="documents__img-button documents__img-button--retry"
                                        title="Reintentar"
                                      >
                                        <AiOutlineReload/>
                                      </Button>
                                      <p className="documents__img-error">{localDocument.error}</p>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </MultiFileUploader>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <h2 className="page-card__title">
            Formularios entregados
          </h2>
          <div className="page-card__content">
            <Row className="mb-4">
              <Col xs={12}>
                {operation.documents.length === 0 && (
                  <p>
                    No hay formularios entregados
                  </p>
                )}

                <div className="scroller">
                  <div className="documents">
                    {operation.documents.map((document) => (
                      <div className="documents__img-container" key={document.id}>
                        <div
                          className="documents__img"
                          style={{backgroundImage: `url('${document.file_url}')`}}
                        >
                          <Button
                            target="_blank"
                            href={document.file_url}
                            className="documents__img-button documents__img-button--download"
                          >
                            <AiOutlineEye/>
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  )
}

OperationFormDocumentation.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client: PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      identification: PropTypes.string.isRequired,
    }),
    status: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
    documentation_download_url: PropTypes.string.isRequired,
    waiting_for_documentation: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
  visible: PropTypes.bool,
  locked: PropTypes.bool,
  onRequestClearance: PropTypes.func,
}

export default OperationFormDocumentation;