import React, {useState} from "react";
import PropTypes from 'prop-types';
import './Simulator.scss';
import {Form} from "react-bootstrap";
import productSimulator from "../../helpers/calculo-cuota-prestamo";
import SliderCustom from "../forms/SliderCustom";

const formatMoney = money => {
  return Number(Number(money).toFixed(0)).toLocaleString();
}

const Simulator = ({product}) => {
  const [amount, setAmount] = useState(product.default_amount);
  const [installments, setInstallments] = useState(product.default_installments);

  const monthlyAmount = productSimulator(product, Number(amount), parseInt(installments)).averages.total;

  return (
    <div className="Simulator">
      <div className="total">
        <h2 className="total__amount">
          ${formatMoney(monthlyAmount)}
        </h2>
        <h5 className="total__description">
          Valor cuota
        </h5>
      </div>
      <Form className="simulator-form">
        <div className="simulator-form__option">
          <h3 className="simulator-form__option_value">
            ${formatMoney(amount)}
          </h3>
          <SliderCustom
            value={amount}
            onChange={setAmount}
            min={product.min_amount}
            max={product.max_amount}
            step={product.step_amount}
            className="simulator-form__option_slider"
            size={40}
          />
          <div className="simulator-form__option_labels">
            <div className="simulator-form__option_label">
              ${formatMoney(product.min_amount)}
            </div>
            <div className="simulator-form__option_label">
              ${formatMoney(product.max_amount)}
            </div>
          </div>
        </div>
        <div className="simulator-form__option">
          <h3 className="simulator-form__option_value">
            {installments} Cuotas
          </h3>
          <SliderCustom
            value={installments}
            onChange={setInstallments}
            step={product.step_installments}
            min={product.min_installments}
            max={product.max_installments}
            className="simulator-form__option_slider"
            size={40}
          />
          <div className="simulator-form__option_labels">
            <div className="simulator-form__option_label">
              {product.min_installments}
            </div>
            <div className="simulator-form__option_label">
              {product.max_installments}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

Simulator.propTypes = {
  product: PropTypes.shape({
    min_amount: PropTypes.number.isRequired,
    max_amount: PropTypes.number.isRequired,
    step_amount: PropTypes.number.isRequired,
    default_amount: PropTypes.number.isRequired,
    min_installments: PropTypes.number.isRequired,
    max_installments: PropTypes.number.isRequired,
    default_installments: PropTypes.number.isRequired,
    step_installments: PropTypes.number.isRequired,
    rci: PropTypes.number.isRequired,
    variable_grant_charge: PropTypes.number.isRequired,
    fixed_grant_charge: PropTypes.number.isRequired,
    variable_administrative_charge: PropTypes.number.isRequired,
    fixed_administrative_charge: PropTypes.number.isRequired,
    life_insurance: PropTypes.number.isRequired,
    tnas: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      installments: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
    }))
  }).isRequired,
};

export default Simulator;