import {
  SAVE_MORE_NOTIFICATIONS,
  SAVE_NEWER_NOTIFICATIONS,
  SAVE_OLDEST_NOTIFICATION_REACHED,
  SAVE_UPDATED_NOTIFICATION,
  SAVE_ERROR_ON_INITIAL_LOAD,
  CLEAR_NOTIFICATIONS,
} from "../types/notifications";

const notificationsDependantState = notifications => {
  return {
    notifications,
    unreadNotificationsCount: notifications ? notifications.filter(noti => noti.read_at === null).length : null,
    oldestNotificationDate: notifications && notifications.length ? notifications[notifications.length - 1].created_at : null,
    newestNotificationDate: notifications && notifications.length ? notifications[0].created_at : null,
  }
}

const initialState = {
  ...notificationsDependantState([]),
  oldestNotificationReached: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //carga inicial y scroll hacia las mas viejas
    case SAVE_MORE_NOTIFICATIONS: {
      const notifications = [...state.notifications || [], ...action.payload];

      return {
        ...state,
        ...notificationsDependantState(notifications),
      }
    }

    case SAVE_NEWER_NOTIFICATIONS: {
      const notifications = [...action.payload, ...state.notifications || []];

      return {
        ...state,
        ...notificationsDependantState(notifications),
      }
    }

    case SAVE_UPDATED_NOTIFICATION: {
      const index = state.notifications.findIndex(noti => noti.id === action.payload.old.id);
      const notifications = state.notifications.slice();

      if(index >= 0){
        notifications.splice(index, 1, action.payload.new)
      }

      return {
        ...state,
        ...notificationsDependantState(notifications),
      }
    }

    case SAVE_OLDEST_NOTIFICATION_REACHED: {
      return {
        ...state,
        oldestNotificationReached: true,
      }
    }

    case SAVE_ERROR_ON_INITIAL_LOAD: {
      return {
        ...state,
        notifications: null,
      }
    }

    case CLEAR_NOTIFICATIONS: {
      return {
        ...initialState
      }
    }

    default: {
      return state
    }
  }
};
