import {useSelector, useDispatch, shallowEqual} from "react-redux"
import { useEffect, useRef } from 'react';
import {SAVE_NEWER_NOTIFICATIONS, CLEAR_NOTIFICATIONS} from "../store/types/notifications";
import {loadMoreNotifications} from "../store/actions/notifications";
import notificationsAPI from "../api/notificationsAPI";

/**
 * Este hook permite subscribirse con websockets a notificaciones del usuario en el backend
 *
 * //TODO: Pendiente a que implementemos los canales de notificacion en el backend post MVP
 *
 * el callback onNotificationReceived permite al usuario del hook mostrar alguna alerta o cartelito al recibirla
 * el hook automaticamente agrega la nueva notificacion al state de redux
 *
 * @param onNotificationReceived
 */
const useNotifications = ({onNotificationReceived, } = {}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const notificationsState = useSelector(state => state.notifications);
  const notificationsStateRef = useRef(notificationsState);

  useEffect(() => {
    notificationsStateRef.current = notificationsState;
  }, [notificationsState]);

  useEffect(() => {
    if(user){
      //Load first notifications
      loadMoreNotifications()(dispatch, () => notificationsStateRef.current);

      /* Fixme: temporal hasta que pongamos WS */
      const refreshInterval = setInterval(async () => {
        const response = await notificationsAPI.index({after: notificationsStateRef.current.newestNotificationDate});

        dispatch({
          type: SAVE_NEWER_NOTIFICATIONS,
          payload: response.data,
        })

        response.data.forEach(notification => {
          onNotificationReceived && onNotificationReceived(notification);
        });
      }, 60 * 1000);
      /* Fixme: temporal hasta que pongamos WS */

      //Subscribe to new notifications
      const subscription = null; //TODO: Connect using websockets to user notification channel

      const notificationReceivedCallback = (notification) => {
        dispatch({
          type: SAVE_NEWER_NOTIFICATIONS,
          payload: [notification],
        });

        onNotificationReceived && onNotificationReceived(notification);
      };

      return () => {
        //TODO: Disconnect using websockets to user notification channel using subscription variable

        dispatch({
          type: CLEAR_NOTIFICATIONS,
        });

        clearInterval(refreshInterval); //Temp hasta que usemos websockets
      }
    }
  }, [user]);
}

export default useNotifications;
