import React, {useState, useCallback} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import './ChangeAvatarModal.scss';
import PropTypes from 'prop-types';
import usersAPI from "../../api/usersAPI";
import MultiFileUploader from "../MultiFileUploader/MultiFileUploader";
import Spinner from "../Spinner/Spinner";

const ChangeAvatarModal = ({show, handleClose, onChanged}) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const onImageAdded = useCallback(async (file) => {
    setLoading(true);

    try{
      const formData = new FormData();
      formData.append('avatar', file);
      const avatar = await usersAPI.changeAvatar(formData);
      onChanged && onChanged(avatar);
      handleClose && handleClose();
    }catch(e){
      if(e.response.status === 422){
        setFeedback({success: false, message: 'El avatar es invalido'});
      }else{
        setFeedback({success: false, message: 'Error al actualizar el avatar'});
      }
    }

    setLoading(false);
  }, [onChanged]);

  return (
    <Modal className="change-avatar ChangeAvatarModal" show={show} onHide={!loading ? handleClose : () => {}}>
      <Form onSubmit={e => e.preventDefault()}>
        <Modal.Header closeButton={!loading}>
          <Modal.Title>Cambiar avatar</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            {loading ? (
              <Spinner size={100}/>
            ) : (
              <MultiFileUploader accept="image/*" onFileAdded={onImageAdded}>
                {({addImage}) => (
                  <Button
                    disabled={feedback && feedback.final}
                    variant="primary"
                    onClick={addImage}
                  >
                    Elegir nuevo avatar
                  </Button>
                )}
              </MultiFileUploader>
            )}
          </div>
          {feedback && (
            <div className={`change-avatar__feedback ${feedback.success ? 'change-avatar__feedback--success' : 'change-avatar__feedback--error'}`}>
              {feedback.message}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleClose}
            disabled={loading}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ChangeAvatarModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onChanged: PropTypes.func,
}

export default ChangeAvatarModal;