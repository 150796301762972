import React, {useState} from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './SettingsPage.scss';
import {connect} from "react-redux";
import {Col, Container, FormGroup, Image, Row} from "react-bootstrap";
import InputCustom from "../../components/forms/InputCustom";
import ChangePasswordModal from '../../components/ChangePasswordModal/ChangePasswordModal';
import moment from 'moment';
import Spinner from "../../components/Spinner/Spinner";
import {setAvatarModalIsOpen} from "../../store/actions/menu";

const SettingsPage = ({user, isUserLoading, setAvatarModalIsOpen}) => {
  //const [showPasswordModal, setShowPasswordModal] = useState(false);

  /*const changePasswordHandler = e => {
    e.preventDefault();
    setShowPasswordModal(true);
  }*/

  const topbarContent = (
    <>
      {isUserLoading ? (<Spinner size={20}/>) : (
        <div className="menu-user menu-user--vertical">
          <div className="menu-user__avatar-container menu-user__avatar-container--big" /*onClick={() => {setAvatarModalIsOpen(true)}}*/>
            <Image
              className="menu-user__avatar"
              src={user.avatar}
              alt="Avatar"
            />
          </div>
          <div className="menu-user__data">
            <span className="menu-user__data-name">
              {user.first_name} {user.last_name}
            </span>
            <span className="menu-user__data-email">
              {user.email}
            </span>
          </div>
        </div>
        )
      }
    </>
  );

  return (
    <DefaultLayout title="Configuracion" topbarContent={topbarContent}>
      <div className="SettingsPage">
        <div className="main-container">
          <Container fluid="xl">
            {isUserLoading ? (<Spinner/>) : (
              <>
                <div className="page-card">
                  <h2 className="page-card__title">
                    Datos del usuario
                  </h2>
                  <div className="page-card__content">
                    <Row className="mb-4">
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.role.name}
                            title="Rol"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.username}
                            title="Usuario"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      {/*
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            type="password"
                            value="------"
                            title="Contraseña"
                            allowShowPassword={false}
                            readOnly={true}
                          />
                          <a href="#" onClickCapture={changePasswordHandler} className="reset-password">
                            Cambiar contraseña
                          </a>
                          <ChangePasswordModal
                            show={showPasswordModal}
                            handleClose={() => setShowPasswordModal(false)}
                          />
                        </FormGroup>
                      </Col>*/}
                    </Row>
                  </div>
                </div>

                <div className="page-card">
                  <h2 className="page-card__title">
                    Datos de contacto
                  </h2>
                  <div className="page-card__content">
                    <Row className="mb-4">
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={`${user.first_name} ${user.last_name}`}
                            title="Nombre"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.email}
                            title="Mail"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.cellphone}
                            title="Celular"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={moment(user.birthdate).format('DD/MM/YYYY')}
                            title="Fecha de nacimiento"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.role.name}
                            title="Cargo"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="page-card">
                  <h2 className="page-card__title">
                    Datos de comercio
                  </h2>
                  <div className="page-card__content">
                    <Row className="mb-4">
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.show_name}
                            title="Nombre fantasia"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.legal_name}
                            title="Razon social"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.identification}
                            title="CUIT"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.address}
                            title="Domicilio"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.phone}
                            title="Teléfono"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.owner_name}
                            title="Nombre titular"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
          </Container>
        </div>
      </div>
    </DefaultLayout>
  );
}

SettingsPage.propTypes = {

};

const mapStateToProps = state => ({
  user: state.auth.user,
  isUserLoading: state.auth.isUserLoading,
});

const mapDispatchToProps = {
  setAvatarModalIsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);