import {Link} from "react-router-dom";
import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';

const Operation = ({operation, to}) => {
  return (
    <Link className="Operation operation" to={to}>
      <div className="operation__inner">
        <p className="operation__line operation__line--title">
          {operation.client.first_name} {operation.client.last_name}
          <span> - </span>
          DNI {operation.client.identification}
        </p>
        <p className="operation__line">
          Op. {operation.id}
          <span> - </span>
          {moment(operation.created_at).format('DD/MM/YYYY')}
        </p>
        <p className="operation__line">
          {operation.status.description}
        </p>
      </div>
    </Link>
  );
}

Operation.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      identification: PropTypes.string.isRequired,
    }),
    status: PropTypes.shape({
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  to: PropTypes.string,
};

export default Operation;