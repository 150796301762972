import React from 'react';
import './EmptyLayout.scss';

const EmptyLayout = (props) => {
  return (
    <div className="EmptyLayout">
      <main>
        {props.children}
      </main>
    </div>
  );
}

EmptyLayout.propTypes = {

};

export default EmptyLayout;