import React, { useEffect, useState } from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './OperationDetailsPage.scss';
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import operationsAPI from "../../api/operationsAPI";
import AsyncContent from "../../components/AsyncContent/AsyncContent";
import OperationForm from "../../components/OperationForm/OperationForm";
import PropTypes from "prop-types";
import useAsyncWrap from "../../hooks/useAsyncWrap";

const OperationDetailsPage = (props) => {
  const asyncWrapper = useAsyncWrap();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [operation, setOperation] = useState(null);

  const loadOperation = async(id) => {
    setLoading(true);
    setLoadingError(null)
    setOperation(null);

    try{
      const response = await asyncWrapper(operationsAPI.show(id));
      setOperation(response.data);
    }catch(e){
      if(!e.isCancelled){
        if(e.response?.status === 404){
          setLoadingError('La operacion no existe');
        }else{
          setLoadingError('Error al cargar datos de operacion');
        }
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    loadOperation(id);
  }, [id]);

  /* Si dejan abierto una hora la pantalla, recargamos por que los links expiran */
  useEffect(() => {
    const reloadInterval = setInterval(() => loadOperation(id), 1000 * 60 * 60);

    return () => {
      clearInterval(reloadInterval);
    }
  }, [id]);
  /* Si dejan abierto una hora la pantalla, recargamos por que los links expiran */

  const handleOperationChanged = operation => {
    setOperation(operation);
  }

  return (
    <DefaultLayout backAction={true} title={`Operacion ${id}`}>
      <div className="OperationDetailsPage">
        <div className="main-container">
          <Container fluid="xl">
            <AsyncContent
              isLoading={loading}
              hasError={!!loadingError}
              errorMessage={loadingError}
              onRetryHandler={() => loadOperation(id)}
            >
              {operation && (
                <OperationForm onOperationChanged={handleOperationChanged} operation={operation}/>
              )}
            </AsyncContent>
          </Container>
        </div>
      </div>
    </DefaultLayout>
  );
}

OperationDetailsPage.propTypes = {
  onOperationChanged: PropTypes.func,
};

export default OperationDetailsPage;