import React from 'react';
import './NotFoundPage.scss';
import {Container} from "react-bootstrap";
import {useSelector} from "react-redux";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import DefaultLayout from '../layouts/DefaultLayout/DefaultLayout';

const NotFoundPage = (props) => {
  const isAuth = useSelector(state => state.auth.isAuth);
  const Layout = isAuth ? DefaultLayout : EmptyLayout;

  return (
    <Layout>
      <div className="NotFoundPage">
        <Container fluid="md" className="container--xs-full main-container">
          <div className="page-card">
            <h2 className="page-card__title">
              Error - No encontrado
            </h2>
            <div className="page-card__content">
              <p>La pagina que busca no existe o fue movida</p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

NotFoundPage.propTypes = {

};

export default NotFoundPage;