import React, {useEffect} from 'react';
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import routes from './config/routes';
import {
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import {connect} from "react-redux";
import {getProfile, logout, login} from "./store/actions/auth";
import useNotifications from './hooks/useNotifications';
import AuthRoute from "./components/AuthRoute/AuthRoute";
import Spinner from "./components/Spinner/Spinner";

function App({loadProfile, isAuth, logout, login, tokenLoaded, isUserLoading}) {
  useNotifications();
  //const history = useHistory();
  //const location = useLocation();

  /* Autologin using token, or load profile if already loggedin */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if(urlParams.has('access_token')){
      login(urlParams.get('access_token'))
        .then(() => {
          urlParams.delete('access_token');
          //history.push(`${window.location.pathname}${urlParams.toString() ? '?' + urlParams.toString() : ''}`)
          //window.location.replace(`${window.location.origin}${window.location.pathname}${urlParams.toString() ? '?' + urlParams.toString() : ''}`);
        });
    }else if(isAuth){
      loadProfile(); //.catch(logout);
    }
  }, []);
  /* Autologin using token, or load profile if already loggedin */

  if(isUserLoading || (!tokenLoaded && isAuth)){
    return (
      <div className="global-loading">
        <Spinner size={100}/>
      </div>
    );
  }

  return (
    <Router>
      <Switch>
        {(Object.keys(routes).map(index => (
          <AuthRoute
              key={index}
              exact
              path={routes[index].path}
              component={routes[index].component}
          />
        )))}
        <AuthRoute path="*" component={NotFoundPage}/>
      </Switch>
    </Router>
  );
}

const mapStateToProps = state => ({
  isUserLoading: state.auth.isUserLoading,
  isAuth: state.auth.isAuth,
  user: state.auth.user,
  tokenLoaded: state.auth.tokenLoaded,
});

const mapDispatchToProps = {
  loadProfile: getProfile,
  logout,
  login,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
