import React, {useCallback, useEffect, useState} from 'react';
import './DefaultLayout.scss';
import TopBar from "../../../components/TopBar/TopBar";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import ChangeAvatarModal from "../../../components/ChangeAvatarModal/ChangeAvatarModal";
import {getProfile} from '../../../store/actions/auth'
import {setAvatarModalIsOpen} from "../../../store/actions/menu";
import OnboardingMenu from "../../../components/OnboardingMenu/OnboardingMenu";
import {closeSideMenu, openSideMenu} from "../../../store/actions/menu";
import {useHistory} from "react-router-dom";
import config from '../../../config/index';

const DefaultLayout = ({
                         title,
                         children,
                         backAction,
                         unreadNotificationsCount,
                         topbarContent,
                         getProfile,
                         setAvatarModalIsOpen,
                         avatarModalIsOpen,
                         user,
                         isUserLoading,
                         token,
                         sideMenuIsOpen,
}) => {
  const history = useHistory();

  const onAvatarChanged = useCallback(async () => {
    await getProfile();
  }, [getProfile]);

  useEffect(() => {
    setAvatarModalIsOpen(false);
  }, [user]);

  return (
    <div className="DefaultLayout">
      <OnboardingMenu
        menu={user?.menu}
        loading={isUserLoading}
        token={token}
        isOpen={sideMenuIsOpen}
        onOpenMenu={openSideMenu}
        onCloseMenu={closeSideMenu}
        logout={() => {}} //Todo: Logout
        onInternalRedirect={history.push}
        platformKey={config.onboardingPlatformKey}
      />
      <div className="main-section">
        <TopBar
          unreadNotificationsCount={unreadNotificationsCount}
          backAction={backAction}
          title={title}
        >
          {topbarContent}
        </TopBar>
        <div className="content-wrapper">
            {children}
        </div>
      </div>

      {avatarModalIsOpen && (
        <ChangeAvatarModal
          handleClose={() => setAvatarModalIsOpen(false)}
          show={avatarModalIsOpen}
          onChanged={onAvatarChanged}
        />
      )}
    </div>
  );
}

DefaultLayout.propTypes = {
  title: PropTypes.string,
  backAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  topBarContent: PropTypes.node,
};

const mapStateToProps = state => ({
  unreadNotificationsCount: state.notifications.unreadNotificationsCount,
  avatarModalIsOpen: state.menu.avatarModalIsOpen,
  sideMenuIsOpen: state.menu.sideMenuIsOpen,
  user: state.auth.user,
  token: state.auth.tokens?.access_token,
  isUserLoading: state.auth.isUserLoading,
});

const mapDispatchToProps = {
  getProfile,
  setAvatarModalIsOpen,
  closeSideMenu,
  openSideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);