import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import './RequestClearOperationModal.scss';
import {useFormik} from "formik";
import * as Yup from "yup";
import operationsAPI from "../../api/operationsAPI";
import PropTypes from 'prop-types';

const RequestClearOperationModal = ({show, handleClose, operation, onOperationChanged}) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const formik = useFormik({
    initialValues: {

    },
    onSubmit: async (values) => {
      setLoading(true);
      setFeedback(null);

      try{
        const response = await operationsAPI.requestClear(operation.id, values);
        setFeedback({message: 'Solicitud de liquidacion exitosa', success: true, final: true});
        onOperationChanged && onOperationChanged(response.data);
      }catch(e){
        if(e.response && e.response.status === 409){
          setFeedback({message: 'Esta operacion no puede solicitar liquidacion', success: false, final: true});
          onOperationChanged && onOperationChanged(e.response.data);
        }else{
          setFeedback({message: 'Ocurrio un error al solicitar liquidacion de la operacion', success: false});
        }
      }

      setLoading(false);
    },
    validationSchema: Yup.object().shape({

    }),
  });

  return (
    <Modal className="request-clear-operation RequestClearOperationModal" show={show} onHide={!loading ? handleClose : () => {}}>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton={!loading}/>

        {feedback && feedback.final ? (
          <>
            <Modal.Body>
              <div className="request-clear-operation__final">
                <h4 className={`request-clear-operation__final-message ${feedback.success ? 'request-clear-operation__final--success' : 'request-clear-operation__final--error'}`}>
                  {feedback.message}
                </h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </>
          ) : (
          <>
            <Modal.Body>
              <h4 className="text-center">
                ¿Esta seguro que desea solicitar la liquidacion de la operacion {operation.id}?
              </h4>
              <h5 className="text-center">
                Asegurese de que la documentacion esta completa y legible.
              </h5>
              {feedback && (
                <div className={`request-clear-operation__feedback ${feedback.success ? 'request-clear-operation__feedback--success' : 'request-clear-operation__feedback--error'}`}>
                  {feedback.message}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={loading}
              >
                Aceptar
              </Button>
            </Modal.Footer>
          </>
          )}
      </Form>
    </Modal>
  );
}

RequestClearOperationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onOperationChanged: PropTypes.func,
}

export default RequestClearOperationModal;