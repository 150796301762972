import Slider from "rc-slider";
import React from "react";
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';

const SliderCustom = ({className, size = 50, ...otherProps}) => {
  const height = size;
  const barHeight = size * 2 / 3;
  const overSize = (height - barHeight) / 2;

  return (
    <Slider
      {...otherProps}
      trackStyle={{backgroundColor: '#84C7E6', height: `${barHeight}px`, borderRadius: `${barHeight / 2}px`}}
      handleStyle={{backgroundColor: '#0055AC', width: `${height}px`, height: `${height}px`, marginTop: `-${overSize}px`, border: 'none'}}
      railStyle={{backgroundColor: '#84C7E6', height: `${barHeight}px`, borderRadius: `${height / 2}px`}}
      style={{height: `${height}px`, padding: `${overSize}px 0`}}
      className={`slider-custom ${className}`}
    />
  )
}

SliderCustom.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  size: PropTypes.number,
}

export default SliderCustom;
