import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import notifications from "./notifications";

export default combineReducers({
    auth,
    menu,
    notifications,
})
