import './Backdrop.scss';
import React from "react";
import PropTypes from 'prop-types';

const Backdrop = ({
    index = 10,
    className = '',
    onClick = () => {},
    onBack = () => {},
  }) => {
  //TODO: Check if esc key/back key is pressed and emit onBack

  return (
    <div
      style={{zIndex: index}}
      className={`Backdrop backdrop ${className}`}
      onClick={onClick}
    />
  );
}

Backdrop.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Backdrop;