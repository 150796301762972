import {useSelector} from "react-redux";
import React from 'react';
import {Route} from 'react-router-dom';
import config from './../../config/index';

const AuthRoute = ({component: Component, ...restProps}) => {
  const authState = useSelector(state => state.auth);
  const redirect = config.onboardingURL + '?redirect_path=' + encodeURIComponent(window.location.pathname) + `&redirect_platform=${config.onboardingPlatformKey}`;

  const render = (props) => {
    if (!authState.isAuth){
      window.location.replace(redirect);
      //return <Redirect to={redirect}/>
    }else{
      return <Component {...props} />
    }
  }

  return <Route {...restProps} render={render}/>
}

AuthRoute.propTypes = {
  //component: PropTypes.node.isRequired,
}

export default AuthRoute;