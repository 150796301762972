import {Button, Col, FormGroup, Row} from "react-bootstrap";
import InputCustom from "../forms/InputCustom";
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import './OperationForm.scss';
import moment from "moment";
import DiscardOperationModal from "../DiscardOperationModal/DiscardOperationModal";
import RequestClearOperationModal from "../RequestClearOperationModal/RequestClearOperationModal";
import OperationFormDocumentation from "../OperationFormDocumentation/OperationFormDocumentation";

const OperationForm = ({operation, onOperationChanged}) => {
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showRequestClearModal, setShowRequestClearModal] = useState(false);

  useEffect(() => {
    if(showDiscardModal && !operation.discardable){
      setShowDiscardModal(false);
    }
  }, [operation, showDiscardModal]);

  return (
    <form className="OperationForm">
      <div className="page-card">
        <h2 className="page-card__title">
          Datos del cliente
        </h2>
        <div className="page-card__content">
          <Row className="mb-4">
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={`${operation.client.first_name} ${operation.client.last_name}`}
                  title="Nombre"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.client.identification}
                  title="DNI"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  type="text"
                  value={operation.client.address}
                  title="Domicilio"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  type="text"
                  value={operation.client.cellphone}
                  title="Telefono celular"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            { operation.client.phones.map(phone => (
              <Col
                md={4}
                key={phone.identification}
              >
                <FormGroup>
                  <InputCustom
                    type="text"
                    value={phone.number}
                    title={phone.name}
                    readOnly={true}
                  />
                </FormGroup>
              </Col>
            ))}
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  type="text"
                  value={operation.client.email}
                  title="Mail"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>

      <div className="page-card">
        <h2 className="page-card__title">
          Datos de la operacion
        </h2>
        <div className="page-card__content">
          <Row className="mb-4">
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.id}
                  title="Número"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.status.description}
                  title="Estado"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={moment(operation.date).format('DD/MM/YYYY')}
                  title="Fecha ingreso"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.approved_at ? moment(operation.approved_at).format('DD/MM/YYYY') : '-'}
                  title="Fecha aprobado"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.approved_amount}
                  title="Monto otorgado"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.approved_installments}
                  title="Plazo otorgado"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.first_due_date ? moment(operation.first_due_date).format('DD/MM/YYYY') : '-'}
                  title="Primer vencimiento de cuota"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.deposited_at ? moment(operation.deposited_at).format('DD/MM/YYYY') : '-'}
                  title="Fecha acreditacion"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <InputCustom
                  value={operation.average_installment_amount || '-'}
                  title="Cuota promedio"
                  readOnly={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {operation.discardable && (
                <Button
                  className="form-button semi-block"
                  variant="outline-primary"
                  onClick={() => setShowDiscardModal(true)}
                >
                  Desistir operacion
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>

      <OperationFormDocumentation
        onRequestClearance={() => setShowRequestClearModal(true)}
        show={operation.documentation_visible === true}
        locked={operation.documentation_locked === true}
        operation={operation}
      />

      {showDiscardModal && (
        <DiscardOperationModal
          show={showDiscardModal}
          handleClose={() => setShowDiscardModal(false)}
          operation={operation}
          onOperationChanged={onOperationChanged}
        />
      )}

      {showRequestClearModal && (
        <RequestClearOperationModal
          show={showRequestClearModal}
          handleClose={() => setShowRequestClearModal(false)}
          operation={operation}
          onOperationChanged={onOperationChanged}
        />
      )}
    </form>
  );
}

OperationForm.propTypes = {
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client: PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      identification: PropTypes.string.isRequired,
    }),
    status: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
    documentation_download_url: PropTypes.string.isRequired,
    waiting_for_documentation: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
}

export default OperationForm;