import { TOGGLE_SIDE_MENU, OPEN_SIDE_MENU, CLOSE_SIDE_MENU, SET_AVATAR_MODAL_IS_OPEN } from "../types/menu";

const initialState = {
  sideMenuIsOpen: false,
  avatarModalIsOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        sideMenuIsOpen: !state.sideMenuIsOpen,
      }

    case OPEN_SIDE_MENU:
      return {
        ...state,
        sideMenuIsOpen: true,
      };

    case CLOSE_SIDE_MENU:
      return {
        ...state,
        sideMenuIsOpen: false,
      }

    case SET_AVATAR_MODAL_IS_OPEN:
      return {
        ...state,
        avatarModalIsOpen: !!action.payload,
      }

    default: {
      return state
    }
  }
};
