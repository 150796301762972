import { AUTH, LOGOUT, SAVE_PROFILE } from "../types/auth"

const initialState = {
    isUserLoading: true,
    user: null,
    isAuth: false,
    tokens: null,
    remember: false,
    tokenLoaded: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH:
            return {
                ...state,
                tokenLoaded: true,
                isUserLoading: true,
                tokens: {
                    ...action.payload.tokens,
                },
                remember: action.payload.remember,
                isAuth: true,
            }

        case LOGOUT:
            return {
                ...initialState,
                tokenLoaded: true,
                isUserLoading: false,
            }

        case SAVE_PROFILE:
            return {
                ...state,
                tokenLoaded: true,
                user: action.payload,
                isUserLoading: false,
            }

        default: {
            return state;
        }
    }
}
