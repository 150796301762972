import SimulatorPage from "../pages/SimulatorPage/SimulatorPage";
import OperationsPage from "../pages/OperationsPage/OperationsPage";
import QrCodePage from "../pages/QrCodePage/QrCodePage";
import NotificationsPage from "../pages/NotificationsPage/NotificationsPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import OperationDetailsPage from "../pages/OperationDetailsPage/OperationDetailsPage";

export default {
  simulator: { path: "/simulador", component: SimulatorPage},
  operations: { path: "/operaciones", component: OperationsPage},
  operation_show: { path: "/operacion/:id", component: OperationDetailsPage},
  qrCode: { path: "/codigo_qr", component: QrCodePage},
  notifications: { path: "/notificaciones", component: NotificationsPage},
  settings: { path: "/configuracion", component: SettingsPage},
}
