import React, {useEffect} from "react";
import './TopBar.scss';
import PropTypes from 'prop-types';
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import route from "../../helpers/route";
import {AiOutlineBell, AiOutlineArrowLeft, AiOutlineMenu} from 'react-icons/ai';
import {toggleSideMenu} from '../../store/actions/menu';

const TopBar = ({
  title,
  backAction,
  toggleSideMenu,
  unreadNotificationsCount = 0,
  children,
}) => {
  const history = useHistory();

  const renderBackIcon = () => {
    let onClick = toggleSideMenu;

    if(backAction){
      onClick = typeof backAction === 'function' ? backAction : history.goBack;
    }

    return (
      <a
        href="#"
        className="topbar__icon"
        onClick={e => {e.preventDefault(); onClick();}}
      >
        {backAction ? <AiOutlineArrowLeft/> : <AiOutlineMenu/>}
      </a>
    );
  }

  return (
    <header className="TopBar topbar">
      <div className="topbar__main-content">
        <div className="topbar__left-buttons-container">
          {renderBackIcon()}
        </div>

        <div className="topbar__title-container">
          {title && (
            <h1 className="topbar__title">
              {title}
            </h1>
          )}
        </div>

        <div className="right-buttons-container">
          <Link
            className={`topbar__icon topbar__icon--notifications ${unreadNotificationsCount > 0 && 'topbar__icon--notifications-new'}`}
            to={route('notifications')}
          >
            <AiOutlineBell/>
          </Link>
        </div>
      </div>

      {children && (
        <div className="topbar__responsive-content">
          {children}
        </div>
      )}
    </header>
  )
}

TopBar.propTypes = {
  title: PropTypes.string,
  backAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  unreadNotificationsCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
};

const mapStateToProps = state => ({
  sideMenuIsOpen: state.menu.isOpen,
});

const mapDispatchToProps = {
  toggleSideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);