import React, {useState, useEffect} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import './DiscardOperationModal.scss';
import {useFormik} from "formik";
import * as Yup from "yup";
import operationsAPI from "../../api/operationsAPI";
import PropTypes from 'prop-types';
import SelectCustom from "../forms/SelectCustom";
import commonAPI from "../../api/commonAPI";

const DiscardOperationModal = ({show, handleClose, operation, onOperationChanged}) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [discardReasons, setDiscardReasons] = useState(null);

  useEffect(() => {
    const loadDiscardReasons = async () => {
      try{
        setDiscardReasons((await commonAPI.getDiscardReasons({})).data);
      }catch(e){
        setDiscardReasons([]);
        setFeedback({success: false, message: 'Error al cargar razones'});
      }
    };

    loadDiscardReasons();
  }, []);

  const formik = useFormik({
    initialValues: {
      discard_reason_id: null,
    },
    onSubmit: async (values) => {
      setLoading(true);
      setFeedback(null);

      try{
        const response = await operationsAPI.discard(operation.id, values);
        setFeedback({message: 'Operacion desistida correctamente', success: true, final: true});
        onOperationChanged && onOperationChanged(response.data);
      }catch(e){
        if (e.response.status === 422) {
          setFeedback({message: 'Algun campo es invalido', success: false});
        }else if(e.response.status === 409){
          setFeedback({message: 'Esta operacion no puede ser desistida', success: false, final: true});
          onOperationChanged && onOperationChanged(e.response.data);
        }else{
          setFeedback({message: 'Ocurrio un error al desistir operacion.', success: false});
        }
      }

      setLoading(false);
    },
    validationSchema: Yup.object().shape({
      discard_reason_id: Yup.string()
        .required('Obligatorio'),
    }),
  });

  return (
    <Modal className="discard-operation DiscardOperationModal" show={show} onHide={!loading ? handleClose : () => {}}>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton={!loading}/>

        {feedback && feedback.final ? (
          <>
            <Modal.Body>
              <div className="discard-operation__final">
                <h4 className={`discard-operation__final-message ${feedback.success ? 'discard-operation__final--success' : 'discard-operation__final--error'}`}>
                  {feedback.message}
                </h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </>
          ) : (
          <>
            <Modal.Body>
              <h4 className="text-center">
                ¿Esta seguro que desea desistir la operacion {operation.id}?
              </h4>
              <div>
                <SelectCustom
                  value={formik.values.discard_reason_id}
                  onChange={formik.handleChange}
                  name="discard_reason_id"
                  id="discard_reason_id"
                  title="Motivo"
                  disabled={loading || discardReasons === null}
                  error={formik.touched.discard_reason_id && formik.errors.discard_reason_id}
                  placeholder={discardReasons === null ? 'Cargando...' : 'Seleccione'}
                >
                  {discardReasons && discardReasons.map(discardReason => (
                    <option key={discardReason.id} value={discardReason.id}>
                      {discardReason.name}
                    </option>
                  ))}
                </SelectCustom>
              </div>
              {feedback && (
                <div className={`discard-operation__feedback ${feedback.success ? 'discard-operation__feedback--success' : 'discard-operation__feedback--error'}`}>
                  {feedback.message}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-primary"
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={loading}
              >
                Aceptar
              </Button>
            </Modal.Footer>
          </>
          )}
      </Form>
    </Modal>
  );
}

DiscardOperationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onOperationChanged: PropTypes.func,
}

export default DiscardOperationModal;