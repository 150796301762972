const calcPureInstallmentAmount = (tem, loanAmount, installments) => {
  const coef = Math.pow(1 + tem / 100, installments);

  return Number((loanAmount * (coef * tem / 100 / (coef - 1))).toFixed(5));
}

const isProductValid = (product) => {
  const fieldsValidation = [
    'variable_grant_charge',
    'fixed_grant_charge',
    'variable_administrative_charge',
    'life_insurance',
  ].map(key => !isNaN(product[key]) && product[key] >= 0);

  const tnasValidation = product?.tnas?.map(tnaData => {
    return !isNaN(tnaData.percentage) && tnaData.percentage > 0 && tnaData.installments === parseInt(tnaData.installments);
  });

  return ![fieldsValidation, tnasValidation].includes(false);
}

/**
 * Simula las cuotas de un prestamo para el producto proporcionado, con el monto y cuotas especificadas
 *
 * @param product
 * @param amount
 * @param installments
 * @returns {{installments: {total: *, lifeInsurance: *, vat: number, interests: *, pure: *}[], averages: *, totals: *}}
 */
export default (product, amount, installments) => {
  const {
    variable_grant_charge,
    fixed_grant_charge,
    variable_administrative_charge,
    //fixed_administrative_charge,
    //rci,
    life_insurance,
    tnas
  } = product;

  /* Validaciones */
  if(isNaN(amount) || amount <= 0){
    throw new Error('amount is invalid');
  }

  if(isNaN(installments) || installments !== parseInt(installments) || installments < 1){
    throw new Error('installments is invalid');
  }

  if(!isProductValid(product)){
    throw new Error('product data is invalid');
  }
  /* Validaciones */

  const tna = tnas?.find(item => item.installments === installments)?.percentage;

  if(tna === undefined){
    throw new Error('no TNA available for the specified installments quantity');
  }

  const tem = tna / (365 / 30);
  const vat = 21;

  //Capital a financiar, varia del total solicitado ya que hay que financiar gastos adicionales. para que le quede el valor que solicito en mano
  const loanAmount = (amount + fixed_grant_charge) / (1 - (variable_grant_charge / 100) * (1 + vat / 100));

  //Cuota pura
  const pureInstallmentAmount = calcPureInstallmentAmount(tem, loanAmount, installments);

  //Gastos administrativos mensuales
  const administrativeMonthlyCharge = loanAmount * variable_administrative_charge / 100;

  let loanAmountLeftToPay = loanAmount;

  /* Creando cuotas */
  const installmentDetails = new Array(installments).fill(null).map(() => {
    //Intereses de esta cuota
    const interestsTotal = loanAmountLeftToPay * tem / 100;

    //Capital de esta cuota
    const capitalTotal = pureInstallmentAmount - interestsTotal;

    //Monto de ganancia de esta cuota
    const monthlyEarningTotal = administrativeMonthlyCharge + interestsTotal;

    //Monto de iva de esta cuota (se calcula sobre la ganancia de esta cuota)
    const vatTotal = monthlyEarningTotal * vat / 100;

    //Costo del seguro de vida (se calcula sobre el capital adeudado)
    const lifeInsuranceTotal = loanAmountLeftToPay * life_insurance / 100;

    //Total a pagar por el cliente en esta cuota
    const total = lifeInsuranceTotal + vatTotal + capitalTotal + interestsTotal + administrativeMonthlyCharge;

    //Descontamos del total adeudado, el capital abonado en esta cuota
    loanAmountLeftToPay -= capitalTotal;

    return {
      vat: Number(vatTotal.toFixed(2)),
      interests: Number(interestsTotal.toFixed(2)),
      lifeInsurance: Number(lifeInsuranceTotal.toFixed(2)),
      pure: Number(pureInstallmentAmount.toFixed(2)),
      total: Number(total.toFixed(2)),
      //debug: {interestsTotal, loanAmountLeftToPay, monthlyEarningTotal, lifeInsuranceTotal, administrativeMonthlyCharge, total, capitalTotal, vatTotal,}
    };
  });
  /* Creando cuotas */

  //"averages" es un objeto con la misma estructura que installmentDetails pero los montos son el promedio de todas las cuotas
  const installmentAverages = Object.keys(installmentDetails[0]).reduce((obj, key) => {
    obj[key] = Math.ceil(
      installmentDetails.reduce((accum, installment) => installment[key] + accum, 0) / installments
    );
    return obj;
  }, {});

  //"totals" es un objeto con la misma estructura que installmentDetails pero los montos son la suma de todas las cuotas
  const installmentTotals = Object.keys(installmentDetails[0]).reduce((obj, key) => {
    obj[key] = installmentDetails.reduce((accum, installment) => installment[key] + accum, 0);
    return obj;
  }, {});

  return {
    installments: installmentDetails,
    averages: installmentAverages,
    totals: installmentTotals,
    //debug: {tem, tna, vat, pureInstallmentAmount,},
  };
}