import {useHistory} from "react-router-dom";
import route from "../../helpers/route";
import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';

const Notification = ({notification, className, onRead}) => {
  const history = useHistory();

  const onClickHandler = async () => {
    if(notification.link){
      if(!notification.read_at && onRead){
        onRead(notification);
      }

      switch(notification.link.entity){
        case 'operation':
          history.push(route({name: 'operation_show', params: {id: notification.link.id}}));
          break;
        default:
          //Unrecognized link
          history.push(route('index'));
      }
    }
  };

  return (
    <article
      onClick={onClickHandler}
      className={`notification ${className} ${!notification.read_at && 'notification--unread'}`}
    >
      <h4 className="notification__header">
        {moment(notification.created_at).format('DD/MM/YYYY')} - {notification.title}
      </h4>
      <p className="notification__description">
        {notification.description}
      </p>
    </article>
  );
}

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    read_at: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    link: PropTypes.shape({
      entity: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  className: PropTypes.string,
  onRead: PropTypes.func,
}

export default Notification;