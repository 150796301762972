import HttpService from "./HttpService";

const httpService = HttpService.getInstance();

export default {
  index: params => httpService.authCall("GET", "/api/comercios/v1/operations", {params}),
  show: id => httpService.authCall("GET", `/api/comercios/v1/operations/${id}`),
  discard: (id, data) => httpService.authCall("PATCH", `/api/comercios/v1/operations/${id}/discard`, {data}),
  requestClear: (id, data) => httpService.authCall("PATCH", `/api/comercios/v1/operations/${id}/request_clear`, {data}),
}
