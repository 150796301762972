import axios from "axios"
import config from "../config/index"
import { store } from "../store"
import {AUTH, LOGOUT} from "../store/types/auth"

class HttpService {
  static instance;

  constructor() {
    this.cancelToken = this.constructor.CancelToken.source();

    this.axios = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      cancelToken: this.cancelToken.token,
    })
  }

  static errorIsCancel(e){
    return axios.isCancel(e);
  }

  static getInstance() {
    if (!HttpService.instance){
      HttpService.instance = new HttpService()
    }

    return HttpService.instance
  }

  cancel = (message = "Cancel request") => {
    this.cancelToken.cancel(message)
  }

  call = async (method, url, body = {}) => {
    try {
      return await this.axios.request({
        method, url, ...body,
      })
    } catch (e) {
      if (e.response?.status === 500){
        console.error("Server Error:", e.response.data.message);
      }

      throw e
    }
  }

  authCall = async (method, url, body = {}) => {
    const state = store.getState()
    const accessToken = state.auth.tokens.access_token

    body.headers = {
      Authorization: `Bearer ${accessToken}`,
    }

    try {
      return await this.call(method, url, body)
    } catch (e) {
      if (e.response?.status === 401) { // Unable to access this resource

        //TODO: Redirect to onboarding login url
        store.dispatch({ type: LOGOUT });
      } else if (e.response?.status === 500) { // Server error
        console.error("Server Error:", e.response);
      }

      throw e;
    }
  }
}

HttpService.CancelToken = axios.CancelToken;

export default HttpService;
