import routes from './../config/routes';

/**
 *
 * @param query
 * @returns {string}
 */
const objToQueryString = (query) => {
  return Object.keys(query)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
    .join('&');
}

/**
 *
 * @param url
 * @param params
 * @returns {*}
 */
const replaceUrlParams = (url, params) => {
  Object.keys(params).forEach(paramKey => {
    const paramValue = params[paramKey];
    url = url.replace(`:${paramKey}`, paramValue);
  });

  return url;
}

/**
 * Helper para generar url de rutas
 *
 * Ejemplos:
 *
 * Existiendo las rutas
 * {
 *   edit_user: {path: '/user/:id/edit, component: ...},
 *   create_user: {path: '/user/create, component: ...},
 * }
 *
 * route({name: 'edit_user', params: {id: 4}, query: {ref: 'google', click_id: 400}}) => /user/4/edit?ref=google&click_id=400
 * route('create_user') => /user/create
 * route({name: 'create_user'}) => /user/create
 * route({name: 'create_user', query: {test: 1}}) => /user/create?test=1
 *
 * //TODO: Permitir selectores de rutas anidados user.create / user.edit, etc. (ingresar al objeto recursivamente) ¿notacion de puntos?
 *
 * @param config
 * @returns {*}
 */
export default (config) => {
  const routeData = (typeof config === 'string') ? {name: config} : config;
  const {name = 'default', query = {}, params = {}} = routeData;
  let url = routes[name]?.path;

  if(!url){
    throw new Error(`Unknown route '${name}'`);
  }

  if(Object.keys(params).length){
    url = replaceUrlParams(url, params);
  }

  /* TODO: si quedan parametros sin reemplazar url.match(regex para encontrar ":xxx") y tirar error */

  if(Object.keys(query).length){
    url+= '?' + objToQueryString(query);
  }

  return url;
};
