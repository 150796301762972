import notificationsAPI from "../../api/notificationsAPI";
import {
  SAVE_MORE_NOTIFICATIONS,
  SAVE_NEWER_NOTIFICATIONS,
  SAVE_OLDEST_NOTIFICATION_REACHED,
  SAVE_UPDATED_NOTIFICATION,
  SAVE_ERROR_ON_INITIAL_LOAD,
  CLEAR_NOTIFICATIONS,
} from "../types/notifications";

export const loadMoreNotifications = () => async (dispatch, getState) => {
  const notificationsState = getState().notifications;

  try{
    const response = await notificationsAPI.index({before: notificationsState.oldestNotificationDate});

    dispatch({
      type: SAVE_MORE_NOTIFICATIONS,
      payload: response.data,
    })

    if(response.data.length === 0){
      dispatch({type: SAVE_OLDEST_NOTIFICATION_REACHED});
    }

    return response.data;
  }catch(e) {
    if (notificationsState.notifications?.length === 0) {
      dispatch({type: SAVE_ERROR_ON_INITIAL_LOAD});
    }

    throw e;
  }
}

export const registerNotificationRead = notification => async dispatch => {
  const response = await notificationsAPI.update(notification.id, {read: true});

  dispatch({
    type: SAVE_UPDATED_NOTIFICATION,
    payload: {
      old: notification,
      new: response.data,
    },
  })

  return response.data;
}