import { AUTH, LOGOUT, SAVE_PROFILE } from "../types/auth"
import usersAPI from "../../api/usersAPI";

export const login = (accessToken, refreshToken) => async dispatch => {
  dispatch({
    type: AUTH,
    payload: {
      tokens: {
        access_token: accessToken,
        //refresh_token: refreshToken,
      },
    },
  });

  try{
    const profileResponse = await usersAPI.profile();

    dispatch({
      type: SAVE_PROFILE,
      payload: profileResponse.data,
    });
  }catch(e){
    /*dispatch({
      type: LOGOUT,
    });*/
  }
}

export const getProfile = () => async dispatch => {
  const response = await usersAPI.profile();

  dispatch({
    type: SAVE_PROFILE,
    payload: response.data,
  });

  return response;
}

export const logout = () => async (dispatch, getState) => {
  dispatch({ type: LOGOUT });
}