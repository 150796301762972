import React from "react";
import PropTypes from 'prop-types';
import './Paginator.scss';

const PageItem = ({onClickHandler, children, disabled, active}) => {
  return (
    <li className={`paginator__page page-item ${disabled && 'disabled'} ${active && 'active'}`}>
      <a onClick={onClickHandler} className="page-link" href="#">
        {children}
      </a>
    </li>
  )
}

PageItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
}

const Paginator = ({meta, isLoading, onPageChangeHandler}) => {
  if(isLoading){
    return (
      <div>Cargando...</div>
    );
  }

  const pagesToShow = [
    ...new Set([
      1,
      1 + 1,
      //...
      meta.current_page - 1,
      meta.current_page,
      meta.current_page + 1,
      //...
      meta.last_page - 1,
      meta.last_page,
    ].filter(page => (page >= 1 && page <= meta.last_page)))
  ];

  const thisPageCount = meta.total ? (meta.to - meta.from + 1) : 0;

  return (
    <nav className="Paginator paginator">
      <p className="paginator__row-count">
        Mostrando {thisPageCount} de {meta.total} registros {/* (Filtrado de un total de X registros) */}
      </p>
      <ul className="paginator__group pagination">
        <PageItem
          disabled={meta.current_page <= 1}
          onClickHandler={() => onPageChangeHandler(meta.current_page - 1)}
        >
          <span>&laquo;</span>
        </PageItem>
        {pagesToShow.map(page => (
          <PageItem
            key={page}
            active={page === meta.current_page}
            onClickHandler={() => onPageChangeHandler(page)}
          >
            <span>{page}</span>
          </PageItem>
        ))}
        <PageItem
          disabled={meta.current_page >= meta.last_page}
          onClickHandler={() => onPageChangeHandler(meta.current_page + 1)}
        >
          <span>&raquo;</span>
        </PageItem>
      </ul>
    </nav>
  )
}

Paginator.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onPageChangeHandler: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    per_page: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    from: PropTypes.number,
    last_page: PropTypes.number.isRequired,
    current_page: PropTypes.number.isRequired,
    to: PropTypes.number,
    total: PropTypes.number.isRequired,
  }),
}

export default Paginator;